<template>
  <div class="modal-mask h-auto">
    <div class="modal-wrapper">
      <div class="modal-container medium">

        <div class="modal-header">
          <div class="close-wrapper">
            <img src="https://s3.ap-northeast-2.amazonaws.com/assets.translink.kr/commons/close.png" @click="closeModal" class="close-btn" />
          </div>
          <p>
            {{ $t('message.password.placeholder') }}
          </p>
        </div>

        <div class="modal-body password-body">
          <div class="input-wrapper">
            <input type="password" class="input" v-model="password" />

            <button class="btn btn-primary" @click="download">{{ $t('message.password.submit') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
  },
  mounted() {
  },
  data() {
    return {
      password: null,
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    download() {
      if (this.password == null || this.password == '') {
        return;
      }
      this.$emit('download', this.password);
    }
  }
}
</script>