var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "ul",
      { staticClass: "item-list" },
      _vm._l(_vm.news, function(n) {
        return _c(
          "li",
          {
            key: n.id,
            staticClass: "news hover",
            on: {
              click: function($event) {
                return _vm.clicked(n)
              }
            }
          },
          [
            _c("div", { staticClass: "news-header" }, [
              _c("span", { staticClass: "uploaded-at" }, [
                _vm._v(_vm._s(n.upload_date))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "news-title-wrapper" }, [
              _c("p", { staticClass: "title ellipsis cut-2" }, [
                _vm._v("\n          " + _vm._s(n.title) + "\n        ")
              ])
            ])
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.hasMore
      ? _c("div", { staticClass: "more-wrapper" }, [
          _c("div", { staticClass: "more-btn", on: { click: _vm.loadMore } }, [
            _vm._v("\n      Load more\n    ")
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }