<template>
  <section class="news financials">
    <section class="news-list-container section-container" v-if="formData">
      <div class="content-container">
        <div class="detail-header">
          <p class="title">
            {{formData.title}}
          </p>
          <span class="uploaded-at ellipsis cut-2">{{formData.upload_date}}</span>
        </div>

        <div class="detail-file-box">
          <div class="file-box" v-if="formData.has_statement">
            <div class="ft">
              {{$t('message.files.fs')}}
            </div>

            <div class="download-btn hover" @click="downloadStart('fs')">
              {{$t('message.files.download')}}
              <a-icon type="download" />
            </div>
          </div>

          <div class="file-box" v-if="formData.has_stockholder">
            <div class="ft">
              {{$t('message.files.st')}}
            </div>

            <div class="download-btn hover" @click="downloadStart('st')">
              {{$t('message.files.download')}}
              <a-icon type="download" />
            </div>
          </div>
        </div>

        <div class="detail-content">
          <p v-for="(content, index) in formData.contents" :key="index">
            {{content}}
          </p>
        </div>
      </div>

    </section>

    <password-modal v-if="showPasswordModal" @closeModal="showPasswordModal = false" @download="download" />
  </section>
</template>

<script>
import PasswordModal from "./passwordModal.vue";
import {EventBus} from "../../util/event-bus";

export default {
  props: {
  },
  data() {
    return {
      id: this.$route.params.id,
      verified: false,
      showPasswordModal: false,
      formData: null,
      fileType: null,
      password: null,
    }
  },
  mounted() {
    this.loadStatement();
    EventBus.$on("langChanged", (lang) => {
      this.loadStatement();
    });
  },
  computed: {
  },
  components: {
    PasswordModal
  },
  methods: {
    loadStatement() {
      axios.get(`/service/api/statements/${this.id}`).then(res => {
        const contents = res.data.statement.content.split('\n');
        this.formData = {
          title: res.data.statement.title,
          contents: contents,
          upload_date: res.data.statement.upload_date,
          has_statement: res.data.statement.has_statement,
          has_stockholder: res.data.statement.has_stockholder,
        };
      });
    },
    downloadStart(fileType) {
      this.fileType = fileType;
      if (this.verified) {
        this.download(this.password);
      } else {
        this.showPasswordModal = true;
      }
    },
    download(password) {
      const self = this;
      axios.post(`/service/api/statements/${this.id}/validate`, {password: password, file_type: this.fileType}).then(res => {
        window.open(`https://assets.translink.kr/${res.data.url}`);
        self.password = password;
        self.verified = true;
        self.showPasswordModal = false;
      }).catch(e => {
        alert('Password is incorrect');
      });
    },
  }
}
</script>