<template>
  <footer class="footer section-container">
    <div class="content-container">
      <div class="link-wrapper">
        <div class="company-info">
          <div class="footer-sub-title">
            <span class="name">Translink Investment</span>
          </div>

          <div class="footer-sub-content">
            <span class="address">
              {{$t('message.footer.address')}}
            </span>
          </div>

          <div class="sns-wrapper">
            <div class="item">
              <a href="https://www.facebook.com/TranslinkInvestment" target="_blank">
                <img src="https://s3.ap-northeast-2.amazonaws.com/assets.translink.kr/footer/facebook.svg" class="sns" />
              </a>
            </div>
            <div class="item">
              <a href="https://www.linkedin.com/company/translink-investment" target="_blank">
                <img src="https://s3.ap-northeast-2.amazonaws.com/assets.translink.kr/footer/linkedin.svg" class="sns" />
              </a>
            </div>
            <div class="item">
              <a href="https://www.youtube.com/channel/UC1yWMxMin6g7wW2r_vMI38w" target="_blank">
                <img src="https://s3.ap-northeast-2.amazonaws.com/assets.translink.kr/footer/youtube.svg" class="sns" />
              </a>
            </div>
          </div>
        </div>

        <div class="site-map">
          <div class="footer-sub-title">
            <span class="name">Company</span>
          </div>

          <ul class="maps">
            <li class="menu">
              <div class="item">
                <router-link :to="{name: 'aboutIndex'}" class="name">
                  <span>About</span>
                </router-link>
              </div>
            </li>
            <li class="menu">
              <div class="item">
                <router-link :to="{name: 'teamIndex'}" class="name">
                  <span>Team</span>
                </router-link>
              </div>
            </li>
            <li class="menu">
              <div class="item">
                <router-link :to="{name: 'portfolioIndex'}" class="name">
                  <span>Portfolio</span>
                </router-link>
              </div>
            </li>
            <li class="menu">
              <div class="item">
                <router-link :to="{name: 'newsIndex'}" class="name">
                  <span>News & Event</span>
                </router-link>
              </div>
            </li>
            <li class="menu">
              <div class="item">
                <router-link :to="{name: 'contentIndex'}" class="name">
                  <span>Content</span>
                </router-link>
              </div>
            </li>
          </ul>
        </div>

        <div class="contact">
          <div class="footer-sub-title">
            <span class="name">Contact Us</span>
          </div>

          <ul class="contact-list">
            <li class="menu">
              <div class="item">
                <a href="mailto:info@translink.kr" class="name mail">
                  <span>Email: info@translink.kr</span>
                </a>
              </div>
            </li>

            <li class="menu">
              <div class="item">
                <span class="name tel">
                  Tel: 02-558-3501
                </span>
              </div>
            </li>

            <li class="menu">
              <div class="item">
                <span class="name tel">
                  Fax: 02-6008-5028
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="copyright-wrapper">
        <div class="copyright">
          ©{{year}} Translink Investment, Corp. All rights are reserved.
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
  },
  computed: {
    year() {
      const date = new Date();
      return date.getFullYear();
    }
  },
  data() {
    return {
    }
  }
}
</script>