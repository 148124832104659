<template>
  <div>
    <ul class="item-list">
      <li class="news hover" v-for="n in news" :key="n.id" @click="clicked(n)">
        <div class="news-header">
          <span class="uploaded-at">{{n.upload_date}}</span>
        </div>
        <div class="news-title-wrapper">
          <p class="title ellipsis cut-2">
            {{n.title}}
          </p>
        </div>
      </li>
    </ul>

    <div class="more-wrapper" v-if="hasMore">
      <div class="more-btn" @click="loadMore">
        Load more
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    news: {
      type: Array,
      default: [],
    },
    hasMore: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
    }
  },
  methods: {
    loadMore() {
      this.$emit('loadMore');
    },
    clicked(row) {
      this.$router.push({name: 'financialShow', params: {id: row.id}});
    }
  }
}
</script>