var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "news financials" },
    [
      _c("section", { staticClass: "news-list-container section-container" }, [
        _c(
          "div",
          { staticClass: "content-container" },
          [
            _c(
              "div",
              { staticClass: "scroll" },
              [
                _c("category-list", {
                  attrs: {
                    "has-all": false,
                    categories: _vm.categories,
                    selectedCategory: _vm.selectedCategory,
                    hasLanguage: true
                  },
                  on: { selected: _vm.selected }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("item-row", {
              attrs: { news: _vm.news, hasMore: _vm.hasMore },
              on: { loadMore: _vm.loadMore, clicked: _vm.clicked }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.selectedRow
        ? _c("password-modal", {
            on: {
              closeModal: function($event) {
                _vm.selectedRow = null
              },
              download: _vm.download
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }