var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "news financials" },
    [
      _vm.formData
        ? _c(
            "section",
            { staticClass: "news-list-container section-container" },
            [
              _c("div", { staticClass: "content-container" }, [
                _c("div", { staticClass: "detail-header" }, [
                  _c("p", { staticClass: "title" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.formData.title) + "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "uploaded-at ellipsis cut-2" }, [
                    _vm._v(_vm._s(_vm.formData.upload_date))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "detail-file-box" }, [
                  _vm.formData.has_statement
                    ? _c("div", { staticClass: "file-box" }, [
                        _c("div", { staticClass: "ft" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("message.files.fs")) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "download-btn hover",
                            on: {
                              click: function($event) {
                                return _vm.downloadStart("fs")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("message.files.download")) +
                                "\n            "
                            ),
                            _c("a-icon", { attrs: { type: "download" } })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formData.has_stockholder
                    ? _c("div", { staticClass: "file-box" }, [
                        _c("div", { staticClass: "ft" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("message.files.st")) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "download-btn hover",
                            on: {
                              click: function($event) {
                                return _vm.downloadStart("st")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("message.files.download")) +
                                "\n            "
                            ),
                            _c("a-icon", { attrs: { type: "download" } })
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "detail-content" },
                  _vm._l(_vm.formData.contents, function(content, index) {
                    return _c("p", { key: index }, [
                      _vm._v("\n          " + _vm._s(content) + "\n        ")
                    ])
                  }),
                  0
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showPasswordModal
        ? _c("password-modal", {
            on: {
              closeModal: function($event) {
                _vm.showPasswordModal = false
              },
              download: _vm.download
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }